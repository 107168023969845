<template>
  <div class="elramed-login-screen">
    <div class="background"></div>
    <div class="login-container">
      <div class="login-top">
        <div class="right">
          <img src="../assets/img/logo.svg" alt="" />
          <div class="login" @keyup.enter="otpScreen()" style="margin-top: 20px !important">
            <div class="title">{{ $localization("web-register-create-account") }}</div>

            <template v-if="!otp">
              <div v-if="false">
                <el-input :placeholder="$localization('web-register-name-surname')" class="fullName primary-input input-bg-white" v-model="form.FullName"></el-input>
                <span class="error">{{ $errorMessage("fullName", ValidationErrors) }}</span>
              </div>
              <el-row class="fullWidth" :gutter="8">
                <el-col :span="12">
                  <el-input :placeholder="$localization('web-register-firstname')" class="fullName primary-input input-bg-white" v-model="form.FirstName"></el-input>
                  <span class="error">{{ $errorMessage("firstName", ValidationErrors) }}</span>
                </el-col>
                <el-col :span="12">
                  <el-input :placeholder="$localization('web-register-lastname')" class="fullName primary-input input-bg-white" v-model="form.LastName"></el-input>
                  <span class="error">{{ $errorMessage("lastName", ValidationErrors) }}</span>
                </el-col>
              </el-row>
              <div class="input-container" v-if="otp == false">
                <div class="radio-group">
                  <form>
                    <label class="radio-row">
                      <input class="radio-button" type="radio" value="false" @change="changeRegisterMethod(false)" v-model="isEmailLogin" />
                      <span class="radio-item">
                        <div class="phone">
                          <vue-phone-number-input
                            v-model="phoneNumberText"
                            @phone-number-focused="changeRegisterMethod(false)"
                            @update="phoneFormatter = $event"
                            :no-example="true"
                            :translations="{
                              countrySelectorLabel: $localization('web-login-country-code'),
                              countrySelectorError: $localization('web-login-select-country-code'),
                              phoneNumberLabel: $localization('web-login-phone-number'),
                              example: $localization('web-login-example'),
                            }"
                            default-country-code="TR"
                            class="primary-input input-bg-white" />
                          <span v-if="$errorMessage('PhoneNumber', ValidationErrors) != null" class="error">{{ $errorMessage("PhoneNumber", ValidationErrors) }}</span>
                        </div>
                      </span>
                    </label>
                    <label class="radio-row">
                      <input class="radio-button" type="radio" value="true" @change="changeRegisterMethod(true)" v-model="isEmailLogin" />
                      <span class="radio-item">
                        <div class="phone">
                          <vue-input-ui class="primary-input input-bg-white" :label="$localization('web-client-form-email')" @focus="changeRegisterMethod(true)" :value="form.Email" v-model="form.Email" autocomplete="off" />
                          <span v-if="$errorMessage('Email', ValidationErrors) != null" class="error">{{ $errorMessage("Email", ValidationErrors) }}</span>
                        </div>
                      </span>
                    </label>
                  </form>
                </div>
              </div>

              <template v-if="IsInstitutionUser">
                <div class="input-container">
                  <vue-input-ui :placeholder="$localization('web-register-institution-code')" :label="$localization('web-register-institution-code')" v-model="form.InstitutionCode" ref="insitutioncode" class="primary-input input-bg-white" />

                  <span class="error">{{ $errorMessage("ınstitutionCode", ValidationErrors) }}</span>
                </div>
              </template>

              <!--
                <template v-if="IsInstitutionUser">
                <div v-if="true" class="input-container">
                  <el-input :placeholder="$localization('web-register-institution-code')"
                    :label="$localization('web-register-institution-code')" v-model="form.InstitutionCode"
                    ref="insitutioncode" class="primary-input input-bg-white">
                  </el-input>
                  <span class="error">{{ $errorMessage("ınstitutionCode", ValidationErrors) }}</span>
                </div>
              </template>

              <div>
                <el-select v-if="countryList.length > 0" v-model="form.CitizenshipId" style="width: 100%" class="nationality" :placeholder="$localization('web-register-nationality')" filterable clearable
                  @change="form.CityId = null; form.DistrictId = null;">
                  <el-option v-for="item in countryList" :key="item.ID" :label="item.Title" :value="item.ID"> </el-option>
                </el-select>
                <span class="error">{{ $errorMessage("CitizenshipId", ValidationErrors) }}</span>
              </div>

              <div v-if="countryList.find((x) => x.ID == form.CitizenshipId) && countryList.find((x) => x.ID == form.CitizenshipId).Code == 'TR'">
                <el-row :gutter="8">
                  <el-col :span="12">
                    <el-select v-model="form.CityId" style="width: 100%" class="tc" :placeholder="$localization('web-register-city')" filterable clearable @change="getDistrictList(form.CityId)">
                      <el-option v-for="item in cityList" :key="item.ID" :label="item.Title" :value="item.ID"> </el-option>
                    </el-select>
                    <span class="error">{{ $errorMessage("CityId", ValidationErrors) }}</span>
                  </el-col>
                  <el-col :span="12">
                    <el-select v-model="form.DistrictId" style="width: 100%" class="tc" :placeholder="$localization('web-register-district')" filterable clearable :disabled="form.CityId == null">
                      <el-option v-for="item in districtList" :key="item.ID" :label="item.Title" :value="item.ID"> </el-option>
                    </el-select>
                    <span class="error">{{ $errorMessage("DistrictId", ValidationErrors) }}</span>
                  </el-col>
                </el-row>
              </div>

              <div>
                <template v-if="countryList.find((x) => x.ID == form.CitizenshipId) && countryList.find((x) => x.ID == form.CitizenshipId).Code != 'TR'">
                  <el-input maxlength="11" :placeholder="$localization('web-register-passport-number')" class="tc" v-model="form.PassportNumber"></el-input>
                  <span class="error">{{ $errorMessage("PassportNumber", ValidationErrors) }}</span>
                </template>
<template v-else>
                  <el-input maxlength="11" v-mask="mask.Identity" :placeholder="$localization('web-register-indentity-number')" class="tc" v-model="form.IdentityNumber"></el-input>
                  <span class="error">{{ $errorMessage("IdentityNumber", ValidationErrors) }}</span>
                </template>
</div>

<div class="input-container">
  <el-date-picker class="birth-date" style="width: 100%" v-model="form.BirthDate" format="dd.MM.yyyy" type="date"
    :placeholder="$localization('web-register-please-select-birthdate')" default-value="1980-01-01" prefix-icon="none">
  </el-date-picker>
  <span class="error">{{ $errorMessage("BirthDate", ValidationErrors) }}</span>
</div>

<div>
  <el-select v-model="form.Gender" :placeholder="$localization('web-register-gender-optional')" class="gender"
    v-if="genderEnums != null" style="width: 100%">
    <el-option v-for="(item, index) in genderEnums.Enums" :key="index" :label="item.DisplayName" :value="item.Key">
    </el-option>
  </el-select>
  <span class="error">{{ $errorMessage("Gender", ValidationErrors) }}</span>
</div>
-->
            </template>

            <div class="otp-wrapper" v-else>
              <div class="otps" @keydown="handleKeydown">
                <el-input ref="otp1" placeholder="" class="otp" v-model="otp1" maxlength="1"></el-input>
                <el-input ref="otp2" placeholder="" class="otp" v-model="otp2" maxlength="1"></el-input>
                <el-input ref="otp3" placeholder="" class="otp" v-model="otp3" maxlength="1"></el-input>
                <el-input ref="otp4" placeholder="" class="otp" v-model="otp4" maxlength="1"></el-input>
                <el-input ref="otp5" placeholder="" class="otp" v-model="otp5" maxlength="1"></el-input>
                <el-input ref="otp6" placeholder="" class="otp" v-model="otp6" maxlength="1"></el-input>
              </div>
              <div class="timer">
                <vue-countdown-timer @end_callback="endCallback" :end-text="$localization('web-register-time-up')" :start-time="new Date().getTime()" :end-time="new Date().getTime() + otpTimerMilisecond" :interval="1000">
                  <template slot="countdown" slot-scope="scope">
                    <div class="otp-timer">
                      <span>{{ scope.props.minutes }}:</span>
                      <span>{{ scope.props.seconds }}</span>
                    </div>
                  </template>
                </vue-countdown-timer>
              </div>
            </div>
            <div class="policy" v-if="!this.otp">
              <div class="policy-container">
                <el-checkbox v-model="policyControl.policy1"></el-checkbox>
                <div class="text">
                  <span @click="openPolicy(0)">{{ $localization("web-register-terms-of-use") }}</span> {{ $localization("web-register-i-aggree") }}
                </div>
              </div>
            </div>
            <div v-if="!this.otp" class="policy-container">
              <!-- <el-checkbox v-model="policyControl.policy2" :append-to-body="true"></el-checkbox> -->
              <div class="text" style="margin-left: 28px">
                <span @click="openPolicy(5)">{{ $localization("web-register-gdpr") }}</span>
              </div>
            </div>
            <!-- env'den gelmesi isteniyorsa =>  :sitekey="this.recaptchaKey" elle girilecekse => sitekey="6Le43WYkAAAAAAD1SG1x1Tt_GYJEfVzFk554jd6g" -->
            <vue-recaptcha ref="recaptcha" @verify="verifyMethod" size="invisible" loadRecaptchaScript :sitekey="this.recaptchaKey"></vue-recaptcha>

            <el-button class="primary-btn fullWidth" v-on:click="otpScreen()" v-if="!otp" :loading="loading" :disabled="loading">{{ IsInstitutionUser ? $localization("web-register-create-account-corporate") : $localization("web-register-create-account-individual") }}</el-button>
          </div>

          <div class="go-login">
            {{ $localization("web-register-i-already-have-account") }} <router-link tag="a" to="/">{{ $localization("web-register-sign-in") }}</router-link>
          </div>
        </div>
      </div>
      <el-button class="back-btn" @click="goWebSite()">{{ $localization("web-register-return-to-home") }}</el-button>
    </div>
    <el-dialog :visible.sync="policyDialog" width="40%" custom-class="policy-dialog">
      <PolicyDialog :data="policy" @checked="checked" />
    </el-dialog>
  </div>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
import PolicyDialog from "../components/PolicyDialog.vue";
// @ is an alias to /src

export default {
  components: { PolicyDialog, VueRecaptcha },
  name: "Register",
  data() {
    return {
      countryList: [],
      cityList: [],
      districtList: [],
      phoneFormatter: null,
      phoneNumberText: "",
      loading: false,
      policyDialog: false,
      policy: null,
      isEmailLogin: false,
      otpTimerMilisecond: 60000,
      policyControl: {
        policy1: false,
        policy2: false,
      },
      form: {
        FullName: "",
        FirstName: "",
        LastName: "",
        PhoneNumber: "",
        Email: "",
        Gender: null,
        BirthDate: null,
        IdentityNumber: null,
        CitizenshipId: null,
        PassportNumber: null,
        GRecaptchaResponse: null,
        CityId: null,
        DistrictId: null,
        IsInstitutionUser: false,
        InstitutionCode: null, // !!! FC 13/03/24 ++
      },
      mask: {
        Identity: "###########",
      },
      ValidationErrors: [],
      otp: false,
      otp1: null,
      otp2: null,
      otp3: null,
      otp4: null,
      otp5: null,
      otp6: null,
      /*recaptchayı enviormentten çekmek için eklendi*/
      recaptchaKey: process.env.VUE_APP_RECAPTCHA,
      IsInstitutionUser: false, //!!! FC 13/04/24++ Params dan alınacak
    };
  },
  async beforeMount() {
    this.IsInstitutionUser = this.$route.params.registertype === "true" ?? false; //!!! FC 13/04/24++ Params dan a

    await this.getCountryList();
    await this.getCityList();
  },
  mounted() {
    if (this.$gtag) {
      this.$gtag.pageview({ page_path: this.$route.path, page_title: "Register | Elra Sağlık" });
    }
  },

  methods: {
    async getCountryList() {
      var res = await this.$client.post("/Country/List", { SearchTerm: "", PageSize: 99999, Page: 1 });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        setTimeout(async () => {
          this.$router.push("/");
        }, 2000);
      } else {
        this.countryList = res.data.Data.Items;
      }
    },
    async getCityList() {
      var res = await this.$client.post("/City/List", { SearchTerm: "" });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        setTimeout(async () => {
          this.$router.push("/");
        }, 2000);
      } else {
        this.cityList = res.data.Data.Items;
      }
    },
    async getDistrictList(id) {
      if (id == null || id == "") {
        this.districtList = [];
        this.form.DistrictId = null;
        this.form.CityId = null;
        return;
      } else {
        var res = await this.$client.post("/District/List", { SearchTerm: "", CityId: id });
        if (res.data.HasError) {
          this.$message.error(res.data.Message);
          setTimeout(async () => {
            this.$router.push("/");
          }, 2000);
        } else {
          this.districtList = res.data.Data.Items;
          this.form.DistrictId = null;
        }
      }
    },
    verifyMethod(payload) {
      this.form.GRecaptchaResponse = payload;
    },
    goWebSite() {
      //window.open("https://elra.health/", "_blank");  !!! HS 02.04.2023  globalyazilim için değiştirildi.
      window.open(process.env.VUE_APP_SITE_URL, "_blank");
    },
    endCallback: function () {
      this.otp = false;
      this.otp1 = null;
      this.otp2 = null;
      this.otp3 = null;
      this.otp4 = null;
      this.otp5 = null;
      this.otpTimerMilisecond = 60000;
    },
    handleKeydown(e) {
      if (e.key === "Backspace" || e.key === "Delete") {
        if (this.otp6 == null || this.otp6 == "") {
          this.$refs.otp5.focus();
        }
        if (this.otp5 == null || this.otp5 == "") {
          this.$refs.otp4.focus();
        }

        if (this.otp4 == null || this.otp4 == "") {
          this.$refs.otp3.focus();
        }

        if (this.otp3 == null || this.otp3 == "") {
          this.$refs.otp2.focus();
        }

        if (this.otp2 == null || this.otp2 == "") {
          this.$refs.otp1.focus();
        }

        if (this.otp1 == null || this.otp1 == "") {
          this.$refs.otp1.focus();
        }
      }
    },
    checked(number, type) {
      var switchChecked = type == 1;
      switch (number) {
        case 0:
          this.policyControl.policy1 = switchChecked;
          break;
        case 5:
          this.policyControl.policy2 = switchChecked;
          break;
      }
      this.policyDialog = false;
    },
    async openPolicy(type) {
      var res = await this.$client.post("/Definition/GetPolicyByType", { Type: type });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.policy = res.data.Data.Policy;
        this.policyDialog = true;
      }
    },
    async otpScreen() {
      this.loading = true;

      this.ValidationErrors = [];
      if (!this.policyControl.policy1) {
        this.$notify({
          title: this.$localization("web-register-warning"),
          message: this.$localization("web-register-you-must-aggree-policies"),
          type: "warning",
          duration: 2000,
        });
        this.loading = false;
        return;
      }
      if (this.isEmailLogin) {
        if (this.form.Email != null && this.form.Email != "") {
          this.form.PhoneNumber = null;
          this.phoneNumberText = "";
        } else {
          this.$message.warning(this.$localization("web-login-please-enter-email"));
          this.loading = false;
          return;
        }
      } else {
        if (this.phoneFormatter != null && this.phoneFormatter.isValid != false) {
          this.form.PhoneNumber = this.phoneFormatter.formatInternational.replaceAll(" ", "");
          this.form.Email = null;
        } else {
          this.$message.warning(this.$localization("web-login-please-enter-valid-phone"));
          this.loading = false;
          return;
        }
      }

      //!!! FC 13/03/24++
      this.form.IsInstitutionUser = this.IsInstitutionUser;

      if (this.IsInstitutionUser == true) {
        if (this.form.InstitutionCode == null || this.form.InstitutionCode.trim().length == 0) {
          this.$message.warning(this.$localization("web-login-please-enter-institution-code"));
          this.ValidationErrors.push({
            Key: "ınstitutionCode",
            Value: this.$localization("web-register-please-fill-field"),
          });

          this.loading = false;

          return;
        }
      }

      var payload = this.form;
      await this.$refs.recaptcha.execute();
      if (this.form.GRecaptchaResponse == null) {
        setTimeout(() => this.otpScreen(), 500);

        return;
      }
      var res = await this.$client.post("/Login/SendSignUpOtpWeb", payload);
      this.form.GRecaptchaResponse = null;
      await this.$refs.recaptcha.reset();
      this.ValidationErrors = res.data.ValidationErrors;

      if (res.data.HasError) {
        this.$notify({
          title: this.$localization("web-register-warning"),
          message: res.data.Message,
          type: "warning",
        });
        if (this.form.IdentityNumber == "" || this.form.IdentityNumber == null) {
          this.ValidationErrors.push({
            Key: "IdentityNumber",
            Value: this.$localization("web-register-please-fill-field"),
          });
          this.loading = false;
          return;
        }
      } else {
        this.otpTimerMilisecond = res.data.Data.ExpireMinute * 60 * 1000;
        this.otp = true;
        this.$notify({
          title: "",
          message: res.data.Message,
          type: "success",
        });
        this.$nextTick(() => {
          this.$refs.otp1.focus();
        });
      }
      this.loading = false;
    },
    /**
     * @param {boolean} emailLogin
     * @returns {void}
     */
    changeRegisterMethod(emailLogin) {
      if (emailLogin) {
        this.form.PhoneNumber = "";
        this.phoneFormatter = null;
        this.phoneNumberText = "";
        this.isEmailLogin = true;
      } else {
        this.form.Email = "";
        this.isEmailLogin = false;
      }
    },
    async verifyOtp() {
      var otpCode = `${this.otp1}${this.otp2}${this.otp3}${this.otp4}${this.otp5}${this.otp6}`;
      var res = await this.$client.post("/Login/ValidateSignUpOtp", { PhoneNumber: this.form.PhoneNumber, Email: this.form.Email, ConfirmationCode: otpCode });
      if (res.data.HasError) {
        this.$notify({
          title: this.$localization("web-login-error"),
          message: res.data.Message,
          type: "error",
        });
        this.$nextTick(() => {
          this.otp1 = null;
          this.otp2 = null;
          this.otp3 = null;
          this.otp4 = null;
          this.otp5 = null;
          this.otp6 = null;
        });
        this.$nextTick(async () => {
          await this.$refs.otp1.focus();
        });
      } else {
        await this.$store.commit("setToken", res.data.Data.Token);
        // #region Analyticss
        await this.$store.dispatch("getUser");
        var user = this.$store.getters.getUser;
        if (this.$gtag && user.Role == 1) {
          this.$gtag.event("new_registration", {
            platform: "web",
            user_id: user.ID,
            IsInstitution: this.IsInstitutionUser,
          });
        }
        //#endregion
        this.$router.push("/");
      }
    },
  },
  computed: {
    genderEnums() {
      return this.$store.getters.getEnums.find((x) => x.Title == "Gender");
    },
    citizenshipEnums() {
      return this.$store.getters.getEnums.find((x) => x.Title == "Citizenship");
    },
  },
  watch: {
    otp1: function () {
      if (this.otp1 != null && this.otp1.length == 1) {
        this.$refs.otp2.focus();
      } else {
        this.$refs.otp1.focus();
      }
    },
    otp2: function () {
      if (this.otp2 != null && this.otp2.length == 1) {
        this.$refs.otp3.focus();
      } else {
        this.$refs.otp1.focus();
      }
    },
    otp3: function () {
      if (this.otp3 != null && this.otp3.length == 1) {
        this.$refs.otp4.focus();
      } else {
        this.$refs.otp2.focus();
      }
    },
    otp4: function () {
      if (this.otp4 != null && this.otp4.length == 1) {
        this.$refs.otp5.focus();
      } else {
        this.$refs.otp3.focus();
      }
    },
    otp5: function () {
      if (this.otp5 != null && this.otp5.length == 1) {
        this.$refs.otp6.focus();
      } else {
        this.$refs.otp4.focus();
      }
    },
    otp6: function () {
      if (this.otp6 != null && this.otp6.length == 1) {
        this.$nextTick(() => {
          this.verifyOtp();
        });
      } else {
        this.$refs.otp5.focus();
      }
    },
  },
};
</script>

<style lang="less">
.policy {
  align-self: start;
}

.go-login {
  text-align: center;
  font-size: 15px; //13
  line-height: 25px; //15
  font-weight: 600; //400
  color: #8697a8;

  a {
    text-decoration: none;
  }
}
</style>
